@import "assets/styles/colors";

.toggleSwitch {
  position: relative;
  margin-right: 10px;
  width: 48px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  .toggleSwitchCheckbox {
    display: none;
  }

  .toggleSwitchLabel {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 12px;
    margin: 0;
    width: 48px;
    height: 20px;
    background-color: $grayishBlue;
    transition: all ease-in 0.2s;

    &:focus {
      outline: none;
    }

    & > span:focus {
      outline: none;
    }
  }

  &.disabled {
    opacity: 0.3;
  }

  .disabled {
    cursor: not-allowed;

    &:before {
      background-color: $white;
      cursor: not-allowed;
    }
  }

  .toggleSwitchInner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: $white;
    color: $white;
    text-align: right;
  }

  .switchDot {
    display: block;
    width: 14px;
    height: 14px;
    background: $white;
    position: absolute;
    top: calc(50% - 7px);
    right: 30px;
    border-radius: 50%;
    transition: all 0.3s ease-in 0s;
  }

  .toggleSwitchCheckbox:checked + .toggleSwitchLabel {
    background-color: $brightOrange;

    .toggleSwitchInner {
      margin-left: 0;
    }

    .switchDot {
      right: 3px;
    }
  }
}
