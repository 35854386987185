@import "assets/styles/colors";

.title {
  font-weight: 300;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 20px;
}

.preloader {
  display: flex;
  justify-content: center;
  padding-top: 70px;
  width: 100%;
}

.overlay {
  background: $darkGray;
  opacity: 0.6;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.display {
  position: absolute;
  top: 40%;
  width: 100%;
  height: 100%;
}

.addCarButton {
  width: 233px;
  margin-bottom: 20px;
}
