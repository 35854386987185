.list {
  list-style-type: none;
  margin-bottom: 8px;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #4c5865;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  line-height: 135.5%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #4c5865;
}
