@import "assets/styles/colors";
@import "assets/styles/mixins";

.container {
  display: flex;
  flex-direction: column;

  @include sm {
    padding: 0 20px;
  }
}

.title {
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 35px;
}

.wrapperCalendar {
  position: relative;
  cursor: pointer;
  align-items: center;
  width: 100%;
  max-width: 494px;
  height: 48px;
  margin-bottom: 25px;
  background-color: #fff;
  border-color: #d9dcdc !important;
  border-style: solid;
  border-width: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  transition: all 100ms;
  box-sizing: border-box;
  border-bottom-width: 1px;

  .icon {
    position: absolute;
    display: flex;
    align-items: center;
    left: 16px;
    top: 13px;

    span {
      padding-top: 3px;
      margin-left: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #66727d;
    }
  }

  .resetBtn {
    position: absolute;
    right: 10px;
    top: 18px;
    z-index: 1;
    width: 25px;
    height: 25px;
  }

  .input {
    span {
      padding-left: 22px;
      color: #bdc2c7;
      font-size: 14px;
      font-weight: 400;

      @include sm {
        padding-left: 10px;
      }
    }
  }
}

.emtyDate {
  @include sm {
    display: flex;

    svg {
      width: 35px;
    }

    span {
      font-size: 12px;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 41px;

  @include sm {
    flex-direction: column;
  }

  > div {
    align-self: stretch;
    background-color: #ffffff;
    width: calc(100% / 3 - 10px);
    padding: 31px 20px;

    @include sm {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

.wrapper_width {
  > div {
    width: calc(50% - 15px);
  }
}

.block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;

  h3 {
    height: 100%;
    max-width: 560px;
    margin-bottom: 20px;
    font-weight: 200;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    word-break: break-word;
    color: #4c5865;
  }

  .orders {
    display: flex;
    flex-direction: column;
    width: 100%;

    .item {
      padding-top: 0;
    }
  }

  .cars {
    width: 100%;
    padding-right: 30px;

    .item {
      border-bottom: 1px solid #edeff0;
    }

    :last-child {
      border-bottom: none;
    }
  }
}

.item {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 37px 0 35px;
  cursor: pointer;

  > span {
    margin-right: 13px;

    path {
      fill: #b7bcc1;
      transition: 0.5s;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    color: #4c5865;

    :first-child {
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      transition: 0.5s;
    }

    :last-child {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }

    .amount {
      font-size: 15px;
      color: #e37639;
    }
  }

  &:hover {
    > span path {
      fill: #e37639;
    }

    .content {
      :first-child {
        color: #e37639;
      }
    }
  }
}

.emptyDate {
  display: flex;
  align-items: center;
}

.action {
  display: flex;
  justify-content: flex-end;
}

.position {
  justify-content: flex-start;
}

.btn {
  max-width: 179px;

  @include sm {
    height: 35px;
    max-width: 100%;
    width: 100%;
  }
}

.line {
  width: calc(100% + 40px);
  padding-left: 20px;
  margin-left: -20px;
  border-bottom: 4px solid #f7f8f8;
}
