@import "assets/styles/colors";

.slider {
  :global .rc-slider-rail {
    background-color: $nero;
    height: 2px;
    border-radius: 0;
  }

  :global .rc-slider-track {
    display: none;
  }
}

.tooltip {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  background-color: $brightOrange;
  border-radius: 32px;
  width: 84px;
  height: 32px;
  border: 0;
  transition: 0.3s background-color;
  margin-top: -16px;
  position: absolute;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    background-color: $orange;
  }
}

.label {
}
