@import "assets/styles/colors";

//.tabsWrapper {
//  overflow-x: auto;
//}

.horizontalTabs {
  border: 1px solid rgba(84, 97, 108, 0.3);
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 2px;
  width: max-content;

  li {
    color: $white;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    height: 34px;
    display: flex;
    align-items: center;
    border-radius: 17px;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;

    span {
      margin-top: 2px;
    }

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      content: "";
      width: 3px;
      transition: all 0.15s;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .selected {
    background-color: $white;
    color: $darkGray;
    font-weight: 700 !important;

    &:hover {
      background-color: $white;
    }
  }

  .disabled {
    opacity: 0.5;
    color: $white !important;
    background-color: rgba(255, 255, 255, 0.3);

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      cursor: default;
    }
  }

  &.grey {
    border: 1px solid $nero;

    li {
      color: $darkGray;

      &.disabled {
        color: $darkGray !important;
      }
    }

    .selected {
      background-color: $nero;
      color: $blackPearl;
    }
  }

  &.orange {
    li {
      color: $sandStone;
    }

    .selected {
      background-color: $brightOrange;
      color: $white;
    }
  }

  &.brightGrey {
    li {
      color: $sandStone;
    }

    .selected {
      background-color: $sandStone;
      color: $white;
    }
  }

  &.menu {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $nero;
    width: 100%;
    max-width: none;

    li {
      color: $sandStone;
      background: none;
      padding: 0 0 13px 0;
      margin-right: 30px;

      span {
        font-weight: 400;
        white-space: nowrap;
      }

      &.selected {
        color: $brightOrange;
        background: none;

        span {
          //font-weight: 700;
        }
      }

      &:hover {
        color: $brightOrange;
        background: none;
      }

      &:before {
        position: absolute;
        display: block;
        border: 1px solid $brightOrange;
        bottom: -2px;
        left: 0;
        width: 100%;
      }

      &.selected:before {
        content: "";
      }
    }
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.verticalTabs {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  width: 110px;

  span {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  li {
    align-items: center;
    display: flex;
    margin: 14px 0;
    font-size: 16px;
    line-height: 1;
    color: $white;
    cursor: pointer;
    height: 40px;
    position: relative;
    //margin-left: -20px;
    //width: calc(100% + 20px);

    span {
      text-align: right;
      display: inline-block;
      width: 80px;
      margin-left: -20px;
    }

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      content: "";
      width: 3px;
      transition: all 0.15s;
    }

    &:hover:after {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  .selected {
    font-weight: 700;

    &:hover:after,
    &:after {
      background-color: $brightOrange;
      right: -1px;
      width: 4px;
    }
  }

  &.menu {
    display: inline-block;
    width: auto;
    border-right: 1px solid $nero;

    li {
      display: flex;
      justify-content: flex-end;
      color: $sandStone;
      padding-right: 38px;

      &.selected {
        color: $brightOrange;
        font-weight: 400 !important;
      }

      &:hover {
        color: $brightOrange;
      }

      span {
        width: auto;
        margin: 0;
        white-space: nowrap;
      }
    }
  }
}

.tabsMobile {
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    display: none;
  }
}

.tabsMobileActive {
  @media screen and (min-width: 500px) and (max-width: 1200px) {
    display: flex;
  }
  @media screen and (min-width: 0px) and (max-width: 500px) {
    display: none;
  }
}

.tabsMobileFullActive {
  display: flex !important;
}

.rectangledTabs {
  height: auto;
  border: none;
  border-radius: 0;

  li {
    padding: 1rem 1.25rem;
    font-weight: 700;
    line-height: 150%;
    height: auto;
    font-size: 0.7rem;
    text-transform: uppercase;
    transition: all 0.15s;
    border-radius: 0;
    cursor: pointer;

    &:hover {
      background-color: transparentize($white, 0.8);
    }

    &:not(.selected) {
      border: 1px solid white;

      &:first-child {
        border-right: none;
      }

      &:last-child {
        border-left: none;
      }
    }
  }

  span {
    min-width: 200px;
    letter-spacing: 1px;
  }

  .selected {
    border: 1px solid $brightOrange;
    background-color: $brightOrange;
    color: $white;

    &:hover {
      background-color: darken($brightOrange, 5%);
    }
  }

  &.grey {
    border: 1px solid $nero;

    li {
      color: $darkGray;
    }

    .selected {
      background-color: $nero;
      color: $blackPearl;
    }
  }
}

@media (max-width: 500px) {
  .horizontalTabs {
    max-width: unset;
    width: 100%;
    margin-right: 0 !important;

    li {
      flex-grow: 1;
      justify-content: center;
    }
  }
}
