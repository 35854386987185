.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 73px;
  padding: 27px 0 27px;
  border-bottom: 1px solid #dce5e5;
}

.left {
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 24px;
  }

  p {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #4c5865;
  }
}

.right {
  display: flex;
  align-items: center;

  > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #a6adb5;
  }

  > div {
    display: flex;
    align-items: center;

    span {
      margin-right: 27px;
      font-weight: 400;
      font-size: 16px;
      color: #4c5865;
    }

    .editBtn {
      width: 173px;
      height: 35px;
      border: 1px solid #e37639;
      border-radius: 30px;

      &:disabled {
        border: 1px solid #a5a5a5;
      }
    }

    input {
      width: 220px;
      height: 48px;
      margin-right: 12px;
      padding-bottom: 12px;

      ::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #a6adb5;
      }
    }
  }

  .benefit {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #a6adb5;
    transition: 0.3s;
  }

  .active {
    color: #4c5865;
  }
}

.input {
  > div {
    position: absolute;
    bottom: -15px;
    left: 5px;
    font-size: 12px !important;
  }
}
