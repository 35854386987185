@import "assets/styles/colors";

.background {
  background-color: $azure;
  height: 100%;
  min-height: 100vh;
}

.container {
  padding: 50px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 20px;
}

.contentInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.textInfo {
  display: flex;

  & > div:not(:last-of-type) {
    margin-right: 20px;
  }
}

.userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.selectType {
  width: 340px;
}

.manager {
  display: flex;
  flex-direction: column;
  width: 290px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: $darkGray;

  .name {
    font-weight: bold;
  }
}

.actionsInfo {
  display: flex;
  align-items: center;

  a {
    display: flex;

    svg {
      margin-right: 8px;
    }

    &:hover {
      svg {
        rect {
          fill: $white;
        }
      }
    }
  }

  .search {
    position: relative;
    width: 290px;
    margin-left: 22px;

    &:before {
      z-index: 1;
      content: "";
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z' fill='%23E37639'/%3E%3C/svg%3E");
      background-size: 18px;
      top: 15px;
      right: 20px;
      width: 18px;
      height: 18px;
    }
  }
}

.activeOrdersChief {
  thead > tr > th {
    &:nth-of-type(1) {
      width: 12%;
    }

    &:nth-of-type(2) {
      width: 12%;
    }

    &:nth-of-type(3) {
      width: 14%;
    }

    &:nth-of-type(4) {
      width: 20%;
    }

    &:nth-of-type(5) {
      width: 11%;
    }

    &:nth-of-type(6) {
      width: 11%;
    }

    &:nth-of-type(7) {
      width: 20%;
    }
  }
}

.table {
  border-collapse: collapse;
  width: 100%;

  .sort {
    position: relative;
    cursor: pointer;

    &::after {
      cursor: pointer;
      content: "";
      position: absolute;
      right: -26px;
      bottom: -1px;
      border: 6px solid transparent;
      border-top: 5px solid $blackCoral;
    }

    &.activeASC {
      &::after {
        border-top: 5px solid $brightOrange;
        transform: rotate(180deg);
        bottom: 4px;
      }
    }
    &.activeDESC {
      &::after {
        border: 6px solid transparent;
        border-top: 5px solid $brightOrange;
      }
    }
  }

  thead > tr > th {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $sandStone;
    padding-bottom: 32px;
    padding-right: 20px;
  }

  tbody {
    .info {
      border-top: 1px solid $grayishBlue;

      td {
        padding-top: 40px;
        padding-bottom: 10px;
      }
    }

    .actions {
      border-bottom: 1px solid $grayishBlue;

      td {
        padding-bottom: 32px;
      }
    }
  }

  tbody > tr > td {
    display: table-cell;
    vertical-align: top;
    padding-right: 10px;
  }

  tbody > tr > td > span {
    display: block;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    margin-top: 4px;
    color: $darkGray;
  }

  .button {
    text-align: right;
    padding-right: 0;

    button:last-child {
      margin-right: 0;
    }

    button {
      margin-right: 20px;
    }
  }

  .tableAction {
    color: $brightOrange;
  }

  .redText {
    color: red;
  }

  .vin {
    margin-top: 10px;
  }

  .phone {
    margin-top: 10px;
  }

  .bold {
    font-weight: bold;
  }
}

.tableFooter {
  margin-top: auto;
}

.preloader {
  display: flex;
  justify-content: center;
  padding-top: 70px;
  width: 100%;
}

.manufactureCarLabel {
  border: 1px solid #e37639;
  padding: 3px 13px;
  text-align: center;
  color: var(--e-37639, #e37639);
  font-size: 13px;
  line-height: 21px;
  margin-top: 10px;
}
