.container {
  max-width: 1280px; //1370 макс размер контейнера
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;

  @media (min-width: 1440px) {
    max-width: 1370px;
  }

  @media (max-width: 1024px) {
    padding: 0 54px;
  }

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: 500px) {
    padding: 0 20px;
  }
}
