@import "assets/styles/colors";

.container {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 50px;
}

.actions {
  display: flex;
  align-items: center;

  a:first-child,
  button:first-child {
    margin-right: 20px;
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: $blackPearl;

  .amountItem {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grayishBlue;
    cursor: pointer;
    margin-right: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: $blackPearl;
    transition: all ease-in 0.1s;

    &:hover {
      border: 1px solid $brightOrange;
      color: $brightOrange;
    }

    &:first-child {
      margin-left: 12px;
    }

    &:last-child {
      margin-right: 12px;
    }
  }
}
