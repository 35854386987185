@import "assets/styles/colors";

.container {
  margin-top: 40px;
  margin-bottom: 80px;
}

.content {
  display: flex;
  justify-content: space-between;
}

.btnBack {
  margin-bottom: 24px;
  padding: 0;
  height: auto;
  color: $blackCoral;

  span {
    width: 18px;
  }
}

.model {
  width: 100%;
  margin-right: 20px;

  .image {
    max-width: 620px;

    img {
      width: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 23px;
  }

  .info {
    display: flex;
    flex-direction: column;
    color: $blackCoral;

    .label {
      font-weight: 300;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    .modification {
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .desc {
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 10px;
    }
  }

  .price {
    display: flex;
    flex-direction: column;
    width: 30%;

    .label {
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: $sandStone;
    }

    .commonPrice {
      font-weight: normal;
      font-size: 22px;
      line-height: 33px;
      text-decoration-line: line-through;
      color: $blackCoral;
      margin-bottom: 2px;
    }

    .currentPrice {
      font-weight: normal;
      font-size: 36px;
      line-height: 33px;
      color: $redDamask;
      margin-top: 6px;
    }
  }

  .options {
  }
}

.insurance {
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #848e98;

  & span {
    font-weight: bold;
  }
}

.directSaleLabel {
  width: fit-content;
  display: inline-block;
  border: 1px solid #e37639;
  padding: 3px 13px;
  text-align: center;
  color: var(--e-37639, #e37639);
  font-size: 13px;
  line-height: 21px;
  margin-top: 10px;
  margin-bottom: 25px;
}
