@import "assets/styles/colors";

.background {
  // background-color: $azure;
}

.container {
  padding-top: 80px;
  padding-bottom: 70px;
}

.titleWithButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;

  .title {
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $darkGray;
  }

  .switch {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      line-height: 14px;
      color: $darkGray;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
  }
}

.loanAmount {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-top: 50px;
}

.creditBank {
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-top: 14px;
  margin-bottom: 20px;
}

.creditInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: $azure;
  padding: 24px 32px;
  margin-bottom: 46px;
}

.creditOptionSolo {
  margin-right: 40px;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 40px;

  .title {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $sandStone;
    margin-bottom: 6px;
  }

  .info {
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    color: $redDamask;
  }
}

.creditOption {
  width: calc(100% / 6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 50px;
  margin-right: 50px;
  border-right: 1px solid #e7e8e9;

  &:nth-child(1) {
    width: calc((100% / 6) * 1.2);
  }

  &:nth-child(2) {
    width: calc((100% / 6) * 1.7);
  }

  &:nth-child(3) {
    width: calc((100% / 6) * 1.1);
  }

  &:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
  }

  .title {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $sandStone;
    margin-bottom: 6px;
  }

  .info {
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    color: $darkGray;
  }

  .infoMonth {
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    color: $redDamask;
  }
}

.creditOptionResidual {
  width: calc(100% / 7);
  padding-right: 30px;
  margin-right: 30px;

  &:nth-child(1) {
    width: calc((100% / 7) * 1.2);
  }

  &:nth-child(2) {
    width: calc((100% / 7) * 1.7);
  }

  &:nth-child(3) {
    width: calc((100% / 7) * 1.1);
  }
}

.formBlock {
  margin-bottom: 15px;
}

.formTitle {
  font-weight: bold;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 24px;
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .columnLeft {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    width: 75%;
  }

  .columnRight {
    display: flex;
    flex-direction: column;
    width: 25%;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;

    * {
      flex-basis: 32%;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    div {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    input {
      background-color: transparent;
    }

    .select {
      div {
        margin-right: 0;
        background-color: transparent;
      }

      svg {
        flex-basis: auto;
        fill: #4c5865;
      }

      :global(.react-select__indicators) {
        max-width: 36px;
      }
    }
  }
}

.creditMainInfo {
  display: flex;
  color: #848e98;
  padding: 25px 0;

  p {
    margin-right: 70px;

    span {
      color: #4c5865;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.switcherBlock {
  width: 100%;
  display: flex;
  margin: 20px 0 45px;
}

.switchGroup {
  margin-right: 35px;

  p {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4c5865;
    margin-left: 8px;
  }
}

.action {
  display: flex;
  justify-content: flex-start;
}
