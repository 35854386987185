@import "assets/styles/colors";

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 10vh;
  padding-bottom: 10vh;
  z-index: 22;
}

.overlay {
  background: $darkGray;
  opacity: 0.6;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  width: 100%;
  max-width: 400px;
  padding: 32px 30px;
  background-color: $brightGray;
  position: relative;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.2));

  .header {
    height: 70px;
    background-color: white;
    margin: -32px -30px 26px;
    display: flex;
    justify-content: center;
  }

  .icon {
    margin-top: 32px;
    height: 64px;
    width: 64px;
  }

  h2 {
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $darkGray;
    margin-top: 50px;
    margin-bottom: 0;
  }

  .description {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: $blackPearl;
    margin-top: 16px;
  }

  .withoutTitle {
    margin-top: 50px;
  }

  .close {
    position: absolute;
    top: 22px;
    right: 18px;
    width: 22px;
    height: 22px;

    svg {
      width: 22px;
      height: 22px;
    }

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: $brightOrange;
        }
      }
    }

    svg {
      path {
        fill: $sandStone;
      }
    }
  }

  .body {
    margin-top: 20px;
  }
}
