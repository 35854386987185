@import "assets/styles/colors";

.btn {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
}
