@import "assets/styles/colors";

.opened {
  .header {
    svg {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .body {
    display: block;
  }
}

.header {
  color: $darkGray;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  margin-top: 28px;

  svg {
    position: absolute;
    right: 33px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    transition: all 0.3s;
  }
}

.body {
  display: none;
}

.row {
  margin-top: 12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: $blackCoral;
}
