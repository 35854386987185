@import "assets/styles/colors";

.btn {
  cursor: pointer;
  height: 34px;
  display: inline-block;
  border-radius: 17px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 9px 18px;
  border: 0;
  outline: none;
  text-align: center;
  white-space: nowrap;
  line-height: 16px;

  color: $white;
  background-color: $brightOrange;

  @media (min-width: 768px) {
    &:hover {
      background: #e6873b;
    }
  }

  &.isWhite {
    color: $aubergine;
    background-color: $white;
  }

  &:disabled {
    background: #a6adb5;
  }
}

@media (max-width: 767px) {
  .btn {
    width: max-content;
    height: 45px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 35px;
  }
}
