@import "assets/styles/colors";

.DayPicker__withBorder {
  box-shadow: none;
  border-radius: 0;
}

.DayPicker_transitionContainer {
  padding-bottom: 28px;
  background: #ffffff; //background
  color: black; //text
  border: none; //default styles include a border
  border-bottom: 1px solid rgba(166, 173, 181, 0.2);
  border-radius: 0;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  background: rgba(227, 118, 57, 0.18);
  color: white;
  border: 1px solid rgba(227, 118, 57, 0.18);
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__hovered_span:hover {
  background: $brightOrange;
  color: white;
  border: 1px solid $brightOrange;
}

.DayPickerNavigation_button__default,
.CalendarDay__default {
  border: none;

  &:hover {
    border: none;
  }
}
