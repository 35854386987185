@import "assets/styles/colors";

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 10vh;
  padding-bottom: 10vh;
  z-index: 22;
}

.overlay {
  background: $darkGray;
  opacity: 0.6;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  padding: 30px;
  background-color: $azure;
  position: relative;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.2));
  overflow-y: scroll;

  .header {
    height: 70px;
    display: flex;
    justify-content: flex-start;
  }

  .icon {
    margin-top: 32px;
    height: 64px;
    width: 64px;
  }

  h2 {
    color: #4c5865;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    letter-spacing: 0.72px;
    text-transform: uppercase;
  }

  .description {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: $blackPearl;
    margin-top: 16px;
  }

  .withoutTitle {
    margin-top: 50px;
  }

  .close {
    position: absolute;
    top: 22px;
    right: 18px;
    width: 22px;
    height: 22px;

    svg {
      width: 22px;
      height: 22px;
    }

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: $brightOrange;
        }
      }
    }

    svg {
      path {
        fill: $sandStone;
      }
    }
  }

  .body {
    margin-top: 20px;
  }

  .fieldsBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;

  .sort {
    position: relative;
    cursor: pointer;

    &::after {
      cursor: pointer;
      content: "";
      position: absolute;
      right: -26px;
      bottom: -1px;
      border: 6px solid transparent;
      border-top: 5px solid $blackCoral;
    }

    &.activeASC {
      &::after {
        border-top: 5px solid $brightOrange;
        transform: rotate(180deg);
        bottom: 4px;
      }
    }
    &.activeDESC {
      &::after {
        border: 6px solid transparent;
        border-top: 5px solid $brightOrange;
      }
    }
  }

  thead > tr > th {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $sandStone;
    padding-bottom: 32px;
    padding-right: 20px;
  }

  tbody {
    .info {
      border-top: 1px solid $grayishBlue;

      td {
        padding-top: 40px;
        padding-bottom: 40px;

        &:nth-child(3) {
          padding-top: 50px;
        }
      }
    }

    .actions {
      border-bottom: 1px solid $grayishBlue;

      td {
        padding-bottom: 32px;
      }
    }
  }

  tbody > tr > td {
    display: table-cell;
    vertical-align: top;
    padding-right: 10px;
  }

  tbody > tr > td > span {
    display: block;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    margin-top: 4px;
    color: $darkGray;
  }
}

.emptyBlock {
  padding: 40px 0;
  background-color: white;
  border-top: 1px solid #d9dcdc;
}

.emptyMsg {
  color: #66727d;
  text-align: center;
  font-size: 36px !important;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 120% !important;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}
