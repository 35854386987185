@import "assets/styles/colors";

.btn {
  display: inline-flex;
  align-items: center;
  height: 34px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 18px 0;
  border: 0;
  outline: none;
  text-align: center;
  white-space: nowrap;
  color: $brightOrange;
  background-color: transparent;
  cursor: pointer;

  .icon {
    margin-right: 0.75rem;
  }

  &.isWhite {
    color: $white;
    background-color: transparent;

    @media (min-width: 768px) {
      &:hover {
        color: $brightOrange;

        svg {
          circle,
          path {
            stroke: $brightOrange;
          }
        }
      }
    }

    svg {
      circle,
      path {
        stroke: $white;
      }
    }
  }
}
