@import "assets/styles/colors";

.container {
  margin-top: 40px;
  margin-bottom: 80px;
}

.title {
  color: #54616c;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.headFlex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.complBox {
  display: flex;
  align-items: center;
  width: 45%;
}

.complTitle {
  width: 125px;
  margin-right: 20px;
  color: #4c5865;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.complName {
  color: #4c5865;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.engineBox {
  display: flex;
  justify-content: space-between;
  width: 55%;
}

.engineItem {
  display: flex;
  width: 50%;
  padding-right: 20px;

  img {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }

  p {
    color: #4c5865;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  span {
    color: #4c5865;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
}

.featuresTable {
  display: flex;
  align-items: stretch;
  border: 2px solid #4c5865;
}

.featuresItem {
  border-right: 2px solid #4c5865;
  color: #4c5865;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 20px;

  &:last-child {
    border-right: 0;
  }
}

.packageItem {
  color: #4c5865;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  padding: 20px;
  border-right: 2px solid #4c5865;
  border-bottom: 2px solid #4c5865;
  border-left: 2px solid #4c5865;
}

.packageItemFull {
  border-top: 2px solid #4c5865;
}
