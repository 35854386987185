@import "assets/styles/colors";

.container {
  border-bottom: 1px solid $grayLight;
  margin-bottom: 1px;
}

.opened {
  .header {
    background-color: $azure;

    svg {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .body {
    display: block;
  }
}

.header {
  color: $darkGray;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  padding: 22px 45px 22px 27px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;

  &:hover {
    background-color: $azure;
  }

  svg {
    position: absolute;
    right: 33px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    transition: all 0.3s;
  }
}

.body {
  display: none;
  padding: 40px 27px 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 100%;
    & > div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }
  }
}

.technical {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  &__label {
    display: flex !important;
  }

  &__value {
    display: flex !important;
  }
}

.cell {
  margin-bottom: 32px;
}

.rowTitle {
  color: $blackPearl;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.label {
  color: $sandStone;
  font-size: 16px;
  margin-bottom: 8px;
}

.value {
  color: $darkGray;
  font-size: 16px;

  p {
    margin-bottom: 12px;
  }
}

.circle {
  padding-left: 17px;
  display: block;
  position: relative;

  span {
    color: $brightOrange;
    position: absolute;
    left: 0;
    top: 1px;
  }
}

.withoutTitleCell {
  @media (min-width: 1025px) {
    & > div {
      flex-basis: 100%;
      padding-right: 0;

      & > div {
        display: flex;
        flex-wrap: wrap;

        & > p {
          width: calc(100% / 3 - 21px * 2 / 3);
          margin-right: 21px;
          align-self: flex-start;

          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .row {
    & > div {
      flex-basis: 100%;
    }
  }
}

.disclaimer {
  margin-top: -20px;
  color: $grayishBlue;
  font-size: 12px;
}
