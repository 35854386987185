@import "assets/styles/colors";
@import "assets/styles/mixins";

.result {
  display: flex;
  //justify-content: space-between;
  justify-content: flex-start;
  margin-bottom: 1.25rem;

  @include lg {
    flex-wrap: wrap;
  }

  &Wrapper {
    position: relative;
    width: 58%;
    border: 4px solid #e7ecec;

    > div {
      padding: 33px 40px 50px 38px;

      @include xs {
        padding: 0;
      }
    }

    @include md {
      width: 100%;
    }

    @include xs {
      border: none;
    }
  }

  .item {
    @include lg {
      flex: 0 0 50%;
      margin-bottom: 20px;
      padding-right: 15px;
    }

    @include md {
      flex: inherit;
      margin-bottom: 0;
      padding-right: 0;
    }

    &:not(:last-child) {
      margin-right: 2.25rem;

      @include lg {
        margin-right: 0;
      }

      @include md {
        margin-right: 2.25rem;
      }

      @include sm {
        margin-right: 0;
      }
    }

    @include sm {
      flex: 0 0 60%;
      margin-bottom: 20px;
      padding-right: 15px;

      &:nth-child(2n) {
        flex: 0 0 40%;
      }
    }

    h5 {
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 18px;
      color: #abb3bb;
    }

    p {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #54616c;
    }
  }
}

.creditPayment {
  .total {
    margin-bottom: 11px;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    color: #abb3bb;

    @include xs {
      margin-bottom: 12px;
    }
  }

  .amount {
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: $darkGray;

    @include xs {
      margin-bottom: 27px;
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  .slider {
    display: flex;
    margin-bottom: 2rem;
    height: 98px !important;

    .item {
      display: flex;
      margin-right: 0.75rem;
      padding: 10px;
      min-width: 276px;
      height: 73px;
      transition: 0.3s background-color;
      background-color: transparentize($white, 0.9);
      border-radius: 0.3rem;

      &.selected {
        background-color: transparentize($white, 0.8);
      }

      &.disabled {
        opacity: 0.6;
      }

      &_active {
        background-color: $blackPearl;
      }

      .text {
        font-size: 0.75rem;
        max-width: 120px;
      }

      .price {
        margin-left: auto;
        font-weight: bold;
      }

      .add {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 10px;
        width: 25px;
        height: 25px;
        font-weight: normal;
        font-size: 16px;
        transition: 0.3s all;
        background-color: $brightOrange;
        border-radius: 50%;
        cursor: pointer;
        line-height: 2;

        &:hover {
          background-color: $orange;
        }
      }
    }
  }

  .actions {
    .getCreditBtn {
      position: absolute;
      bottom: -20px;
      left: 36px;
      height: 41px;
      padding: 13px 23px;
      font-weight: bold;
      font-size: 12px;
      line-height: 140%;
      border-radius: 30px;

      @include xs {
        position: static;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }

    .topButtons {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      flex-wrap: wrap;

      @include sm {
        justify-content: space-between;
        margin-bottom: 10px;

        button {
          margin-bottom: 10px;
        }

        a {
          margin-right: 0;
          width: 100%;
        }
      }
    }

    button {
      margin-right: 2rem;

      @include sm {
        margin-right: 0;
      }
    }

    @include sm {
      flex-wrap: wrap;
    }

    .bottomButtons {
      display: flex;
      align-items: center;
      width: 100%;

      @include sm {
        border-top: 1px solid #222;
        margin-top: 20px;
        padding-top: 20px;
        justify-content: space-between;

        button,
        a {
          color: #fff;

          path {
            fill: #fff;
          }

          circle {
            stroke: #fff;
          }
        }

        a {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

.desktopText {
  display: inline-block;
  margin-right: 3px;

  @include xs {
    display: none;
  }
}

.tablet {
  display: none;

  @include md {
    display: block;
  }

  @include xs {
    width: 100%;
  }
}

.mobile {
  display: none;

  @include xs {
    display: block;
  }
}
