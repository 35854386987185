@font-face {
  font-family: 'LADAPragmatica';
  font-style: normal;
  font-weight: normal;
  src: local('LADAPragmatica'),
    url('assets/fonts/LADAPragmatica.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'LADAPragmatica';
  font-style: normal;
  font-weight: 700;
  src: local('LADAPragmatica'),
    url('assets/fonts/LADAPragmatica-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'LADAPragmatica';
  font-style: normal;
  font-weight: 300;
  src: local('LADAPragmatica'),
    url('assets/fonts/PragmaticaLightC.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local('Roboto');
  unicode-range: U+20BD;
  font-display: swap;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overscroll-behavior: none;
  overflow: auto;
}

body {
  margin: 0;
  font-family: LADAPragmatica, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #e37639;
}

h1,
h2 {
  font-style: normal;
  font-weight: 300;
}

@media screen and (max-width: 1024px) {
  * {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }

  input,
  textarea {
    -webkit-user-select: text;
    -webkit-touch-callout: default;
  }
}

.survio-widget {
  position: relative;
  z-index: 11;
}
