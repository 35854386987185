@import "assets/styles/colors";

.container {
  display: flex;
  align-items: center;

  button {
    &:nth-child(1) {
      padding-right: 5px;
    }

    &:nth-child(3) {
      padding-left: 4px;
    }

    &:focus {
      outline: 1px solid $brightOrange;
    }
  }
}

.space {
  width: 4px;
}

.lilSpace {
  width: 0;
  margin-left: -14px;
}

.borderNone {
  border: 0;
}
