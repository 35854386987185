@import "assets/styles/colors";

.container {
  display: flex;
  width: 290px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 16px;
  background-color: white;

  .text {
    max-width: 90px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    text-align: right;
    color: $sandStone;
  }

  .amount {
    font-weight: bold;
    font-size: 36px;
    line-height: 21px;
    color: $blackCoral;
    margin-left: 20px;

    &.bright {
      color: $brightOrange;
    }
  }
}
