@import "assets/styles/colors";

.wrapper {
  padding: 3px;
  border: 1px solid #eaefef;
  border-radius: 100px;
  width: fit-content;
  display: flex;
  background: #fff;
}

.grayText {
  color: #4c5865;
}

.selection {
  padding: 6px 15px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 100px;

  &_select {
    background: $brightOrange;
    color: #fff;

    .grayText {
      color: #fff;
    }
  }
}
