@import "assets/styles/colors";

.background {
  position: fixed;
  bottom: 0;
  margin-top: 10px;
  background-color: #fff;
  width: 100%;
  z-index: 5;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.container {
  padding: 24px 15px;
  display: flex;
  justify-content: space-between;
  max-width: 1280px; //1370 макс размер контейнера
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1440px) {
    max-width: 1370px;
  }

  @media (max-width: 1024px) {
    padding: 0 54px;
  }
}

.links {
  display: flex;
}

.itemPdf {
  &:hover {
    svg {
      circle {
        stroke: $brightOrange;
      }

      path {
        stroke: $brightOrange;
      }

      line {
        stroke: $brightOrange;
      }
    }

    span {
      color: $brightOrange;
    }
  }
}

.itemEmail {
  &.itemEmailDisabled {
    &:hover {
      cursor: default;

      svg {
        circle {
          stroke: $darkGray;
        }

        path {
          fill: $darkGray;
        }
      }

      span {
        color: $darkGray;
      }
    }
  }

  &:hover {
    svg {
      circle {
        stroke: $brightOrange;
      }

      path {
        fill: $brightOrange;
      }
    }

    span {
      color: $brightOrange;
    }
  }
}

.itemPdf,
.itemEmail {
  display: flex;
  align-items: center;
  margin-right: 46px;
  cursor: pointer;

  span {
    margin-left: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $darkGray;
  }
}

.buttons {
  display: flex;
  align-items: center;

  .actionBtn {
    margin-left: 16px;
  }
}
