@import "assets/styles/colors";

.button {
  max-width: 1440px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $orange;
  margin: 0 auto;

  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: $white;

  &:hover {
    cursor: pointer;
  }
}
