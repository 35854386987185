@import "assets/styles/colors";

.carsPlaced {
  tbody > tr > th {
    &:nth-of-type(1) {
      width: 15%;
    }

    &:nth-of-type(2) {
      width: 35%;
    }

    &:nth-of-type(3) {
      width: 50%;
    }
  }
}

.title {
  font-weight: 300;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 20px;
}

.table {
  border-collapse: collapse;
  width: 100%;

  .sort {
    position: relative;
    cursor: pointer;

    &::after {
      cursor: pointer;
      content: "";
      position: absolute;
      right: -26px;
      bottom: -1px;
      border: 6px solid transparent;
      border-top: 5px solid $blackCoral;
    }

    &.activeASC {
      &::after {
        border-top: 5px solid $brightOrange;
        transform: rotate(180deg);
        bottom: 4px;
      }
    }
    &.activeDESC {
      &::after {
        border: 6px solid transparent;
        border-top: 5px solid $brightOrange;
      }
    }
  }

  thead > tr > th {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $sandStone;
    padding-bottom: 32px;
    padding-right: 20px;
  }

  tbody {
    .info {
      border-top: 1px solid $grayishBlue;

      td {
        padding-top: 40px;
        padding-bottom: 40px;

        &:nth-child(3) {
          padding-top: 50px;
        }
      }
    }

    .actions {
      border-bottom: 1px solid $grayishBlue;

      td {
        padding-bottom: 32px;
      }
    }
  }

  tbody > tr > td {
    display: table-cell;
    vertical-align: top;
    padding-right: 10px;
  }

  tbody > tr > td > span {
    display: block;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    margin-top: 4px;
    color: $darkGray;
  }

  .deleteButton {
    margin-right: 10px;
  }

  .button {
    text-align: right;
    padding-right: 0;

    button {
      margin-left: 20px;
    }
  }

  .tableAction {
    color: $brightOrange;
  }

  .vin {
    margin-top: 10px;
  }

  .phone {
    margin-top: 10px;
  }

  .bold {
    font-weight: bold;
  }
}

.tableFooter {
  margin-top: auto;
}

.preloader {
  display: flex;
  justify-content: center;
  padding-top: 70px;
  width: 100%;
}

.addCarButton {
  width: 233px;
  margin-bottom: 20px;
}

.emptyBage {
  height: auto !important;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #d9dcdc;

  p {
    color: #66727d;
    text-align: center;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 120% !important;
    letter-spacing: 0.72px;
    text-transform: uppercase;
  }
}
