@import "assets/styles/colors";

@mixin default-font {
  font-weight: 700;
  color: $blackPearl;
  text-transform: uppercase;
}

.h1 {
  @include default-font;
  font-size: 48px;
  font-weight: 300;
}

.h2 {
  @include default-font;
  font-size: 36px;
  font-weight: 300;
  line-height: 120%;
}

.h3 {
  @include default-font;
  font-size: 24px;
  font-weight: 300;
}

.h4 {
  @include default-font;
}

.h5 {
  @include default-font;
}

.h6 {
  @include default-font;
}

.p {
  color: $blackPearl;
  line-height: 1.4;
}
