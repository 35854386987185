.container {
  padding-top: 40px;
  padding-bottom: 34px;
}

.showRulesText {
  display: inline-block;
  font-weight: 300;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #4c5865;
  margin-bottom: 32px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.rulesModal {
  max-width: 800px;
  z-index: 6;
}

.rulesTitle {
  text-align: left !important;
  font-size: 22px !important;
}

.rulesText {
  text-align: left !important;
}
