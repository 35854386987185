@import "assets/styles/colors";

.background {
  background-color: $azure;
  height: 100%;
}

.container {
  padding: 50px 0 100px 0;

  > :nth-child(3n) {
    margin-bottom: 30px;
  }
}

.title {
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  div {
    margin-right: 20px;
  }

  div:last-child {
    margin-right: 0;
  }
}

//.info {
//  width: 50%;
//  display: flex;
//  margin-right: 20px;
//}

.actions {
  width: 33%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  button:last-child {
    margin-left: 16px;
  }

  button > span {
    display: flex;
  }

  button > span > svg {
    margin-right: 10px;
  }

  button:hover {
    span > svg > rect {
      fill: white;
    }
  }
}

.item {
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 35px 20px 35px 54px;
  min-width: 260px;
}

.titleItem {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $sandStone;
  margin-bottom: 12px;
}

.amount {
  font-weight: bold;
  font-size: 36px;
  line-height: 21px;
  color: $blackCoral;
}

.bright {
  color: $redDamask;
}

.preloader {
  display: flex;
  justify-content: center;
  padding-top: 150px;
  width: 100%;
}
