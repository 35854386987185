@import "assets/styles/colors";

.title {
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 32px;
}

.formLine {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  &.full {
    display: block;

    & .input {
      width: calc(50% - 10px);
    }
  }

  & .input {
    width: 50%;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
}

.requiredText {
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #9d9d9d;
  margin-bottom: 32px;
}
