@import "assets/styles/colors";

.background {
  margin-top: 10px;
  background-color: $azure;
}

.container {
  padding: 48px 0;
  display: flex;
  justify-content: flex-end;
}

.links {
  display: flex;
}

.itemPdf {
  &:hover {
    svg {
      circle {
        stroke: $brightOrange;
      }

      path {
        stroke: $brightOrange;
      }

      line {
        stroke: $brightOrange;
      }
    }

    span {
      color: $brightOrange;
    }
  }
}

.itemEmail {
  &:hover {
    svg {
      circle {
        stroke: $brightOrange;
      }

      path {
        fill: $brightOrange;
      }
    }

    span {
      color: $brightOrange;
    }
  }
}

.itemPdf,
.itemEmail {
  display: flex;
  align-items: center;
  margin-right: 46px;
  cursor: pointer;

  span {
    margin-left: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $darkGray;
  }
}

.buttons {
  display: flex;
  align-items: center;

  button {
    margin-left: 16px;
  }
}
