@import "assets/styles/colors";
@import "assets/styles/mixins";

.creditBlock {
  // background-color: $azure;
  padding: 73px 0 54px;
}

.title {
  color: $darkGray;
  font-weight: 300;
  font-size: 48px;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 18px;

  @include xs {
    font-size: 32px;
    line-height: 38.4px;
    margin-bottom: 23px;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 38px 36px 54px;

  @include md {
    flex-direction: column;
  }

  @include xs {
    padding: 33px 22px 38px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 76px;

  @include xs {
    margin-bottom: 48px;
  }
}

.block {
  margin: 40px 0;

  @include xs {
    margin: 34px 0 36px;

    :global .rc-slider-step {
      background-color: #e37639;
      height: 2px;
    }
  }
}

.blockInline {
  margin-bottom: 10px;
}

.mobileText {
  display: none;

  @include xs {
    display: inline;
  }
}

.desktop {
  @include xs {
    display: none;
  }
}

.deskButton {
  @include md {
    display: none;
  }
}

.mobile {
  display: none;

  @include xs {
    display: block;
  }
}

.creditProgram {
  position: relative;
  width: calc(42% - 46px);
  margin-right: 46px;

  & > div:first-child {
    margin: 0;
  }

  @include md {
    width: 70%;
  }

  @include xs {
    width: 100%;
  }

  .tabs {
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex !important;
    max-width: 100%;

    @include lg {
      min-width: inherit;
    }

    @include xs {
      li {
        flex: 0 0 50%;
        padding: 10px 20px;

        span {
          min-width: inherit;
        }
      }
    }
  }

  h4 {
    margin-bottom: 17px;
    color: $blackCoral;
    font-size: 15px;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;

    @include xs {
      display: inline-flex;
    }
  }

  .initialFee {
    display: flex;
    align-items: center;

    @include xs {
      flex-direction: column;
    }
  }

  .result {
    margin-right: 1.5rem;
    width: 196px;
    flex: 0 0 196px;

    @include xs {
      width: 100%;
      flex: 1 0 auto;
      margin-right: 0;
      margin-bottom: 21px;
    }

    input {
      color: $azure;
      padding: 14px;
      -webkit-text-fill-color: $darkGray;
      opacity: 1; /* required on iOS */

      &:disabled {
        background-color: $azure !important;
      }
    }
  }
}

.mobileTabs {
  display: flex !important;
  max-width: max-content !important;
  width: inherit !important;
  background-color: $white;
}

.button {
  margin-top: 22px;
}

.tabsColor {
  background-color: $white;
}
