@import "assets/styles/colors";

.background {
  background-color: $azure;
}

.container {
  padding-top: 80px;
  padding-bottom: 70px;
}

.title {
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 32px;
}

.wrapper {
  margin-bottom: 40px;

  h3 {
    margin-bottom: 31px;
    font-weight: 700;
    font-size: 18px;
    line-height: 135.5%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #4c5865;
  }

  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.message {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 33px;
  border: 3px solid #e37639;

  span {
    padding: 31px 0 29px;
    color: #e37639;
  }
}
