.react-select__control {
  position: static;
  min-height: 48px;
  border-color: #efefef !important;
  height: 48px;
  border-radius: 0px !important;
}

.react-select-container {
  position: static;
}

.react-select-container-spec .react-select__control {
  position: static;
  border-top-width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  background: none !important;
  border-color: rgba(166, 173, 181, 0.5) !important;
}

.react-select-container-spec .react-select__single-value {
  color: #fff !important;
}

.react-select-container-spec .react-select__value-container span {
  /*color:*/
}

.react-select-container-spec path {
  fill: #e37639;
}
.react-multi-select__dropdown-indicator {
  color: #e37639 !important;
}

.react-select__dropdown-indicator {
  color: #e37639 !important;
}

.react-select__indicator-separator {
  display: none;
  background-color: #efefef !important;
}

.react-select__control--is-disabled {
  background-color: #fafafa !important;
}

.react-select__control--is-focused {
  border: 1px solid rgba(166, 173, 181, 0.2);
  box-shadow: none !important;
  border-color: rgba(166, 173, 181, 0.2) !important;
}

.react-select__placeholder {
  display: none;
}

.react-select__value-container {
  color: #363839;
  font-size: 15px;
  margin: 15px 0 8px 6px;
}

.react-select__multi-value {
  padding: 2px;
  background-color: #f4f4f4 !important;
}

.react-select__menu {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2) !important;
  background-color: #ffffff !important;
}

.react-select__option {
  color: #6f7981 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  background: none !important;
}

.react-select__option--is-focused {
  color: #fa820f !important;
  cursor: pointer !important;
  position: relative;
}

.react-select__option--is-focused:before {
  content: '';
  position: absolute;
  right: 20px;
  width: 16px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12'%3E%3Cg%3E%3Cg fill='%23c4c4c4'%3E%3Cpath d='M14.952.695a.75.75 0 0 0-1.06 0L4.906 9.68 1.452 6.226a.75.75 0 0 0-1.06 1.061l3.984 3.984a.75.75 0 0 0 1.06 0l9.516-9.516a.75.75 0 0 0 0-1.06z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.react-select__option--is-selected {
  color: #fa820f !important;
}

.react-select__option--is-selected:before {
  content: '';
  position: absolute;
  right: 20px;
  width: 16px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12'%3E%3Cg%3E%3Cg fill='%2327ae60'%3E%3Cpath d='M14.952.695a.75.75 0 0 0-1.06 0L4.906 9.68 1.452 6.226a.75.75 0 0 0-1.06 1.061l3.984 3.984a.75.75 0 0 0 1.06 0l9.516-9.516a.75.75 0 0 0 0-1.06z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.react-select__indicators {
  height: 100%;
}

.tabsSelect__control--is-focused {
  border: 1px solid rgba(166, 173, 181, 0.2);
  box-shadow: none !important;
  border-color: rgba(166, 173, 181, 0.2) !important;
}

.tabsSelect__value-container {
  color: #363839;
  font-size: 15px;
  text-align: left;
}

.tabsSelect__option {
  color: #6f7981 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  background: none !important;
  padding-right: 40px !important;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
}

.tabsSelect__option--is-selected {
  color: #fa820f !important;
}

.tabsSelect__option--is-selected:before {
  content: '';
  position: absolute;
  right: 20px;
  width: 16px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12'%3E%3Cg%3E%3Cg fill='%2327ae60'%3E%3Cpath d='M14.952.695a.75.75 0 0 0-1.06 0L4.906 9.68 1.452 6.226a.75.75 0 0 0-1.06 1.061l3.984 3.984a.75.75 0 0 0 1.06 0l9.516-9.516a.75.75 0 0 0 0-1.06z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

/*Dark Style */
.react-select-container-dark .react-select__control {
  position: static;
  border: 1px solid rgba(217, 220, 220, 0.3) !important;
  background: none !important;
  border-radius: 5px !important;
  height: 41px;
  padding-right: 20px;
  background: #000 !important;
}

.react-select-container-dark path {
  fill: #ffff;
}

.react-select-container-dark .react-select__value-container {
  padding: 0 0 0 19px;
  margin: 0;
}

.react-select-container-dark .react-select__single-value {
  color: #fff !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

.react-select-container-dark .react-select__menu {
  background: #262626 !important;
  border-radius: 5px !important;
  padding: 0 !important;
  margin-top: 4px;
  overflow: hidden;
}

.react-select-container-dark .react-select__option {
  color: #fff !important;
  min-height: 35px;
}

.react-select-container-dark
  .react-select__option.react-select__option--is-disabled {
  color: #fff !important;
  min-height: 35px;
  opacity: 0.5;
}

.react-select-container-dark .react-select__option--is-selected {
  color: #fff !important;
  background: #353535 !important;
}

.react-select-container-dark .react-select__option--is-selected:before {
  display: none !important;
}

.react-select-container-dark .react-select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/*With bage */
.react-select-container-bage .react-select__option:nth-last-child(3) {
  position: relative;
}

.react-select-container-bage .react-select__option:nth-last-child(3):before {
  content: '';
  position: absolute;
  top: 0;
  width: calc(100% - 28px);
  height: 1px;
  left: 14px;
  background: #d9dcdc;
}

/* multi select styles */
.react-multi-select__control {
  position: static;
  min-height: 48px;
  border-color: #efefef !important;
  height: 48px;
  border-radius: 0 !important;
}

.react-multi-select-container {
  position: static;
}

.react-multi-select__indicator-separator {
  display: none;
  background-color: #efefef !important;
}

.react-multi-select__control--is-disabled {
  background-color: #fafafa !important;
}

.react-multi-select__control--is-focused {
  border: 1px solid rgba(166, 173, 181, 0.2);
  box-shadow: none !important;
}

.react-multi-select__placeholder {
  display: none;
}

.react-multi-select__value-container {
  color: #363839;
  font-size: 15px;
  margin: 15px 0 8px 6px;
}

.react-multi-select__multi-value {
  background-color: #f4f4f4 !important;
}

.react-multi-select__menu {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2) !important;
  background-color: #ffffff !important;
  padding: 12px 2px 2px !important;
}

.react-multi-select__option {
  color: #6f7981 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  background: none !important;
  position: relative !important;
  padding-left: 46px !important;
  padding-top: 4px !important;
  line-height: 1.5 !important;
  margin-bottom: 6px !important;
}

.react-multi-select__option:before {
  content: '';
  position: absolute;
  left: 12px;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #e3e3e3;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
}

.react-multi-select__option--is-focused {
  cursor: pointer !important;
  position: relative;
}

.react-multi-select__option--is-focused:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12'%3E%3Cg%3E%3Cg fill='%23c4c4c4'%3E%3Cpath d='M14.952.695a.75.75 0 0 0-1.06 0L4.906 9.68 1.452 6.226a.75.75 0 0 0-1.06 1.061l3.984 3.984a.75.75 0 0 0 1.06 0l9.516-9.516a.75.75 0 0 0 0-1.06z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.react-multi-select__option--is-selected:before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.5 1L5.5 9L1 5' stroke='%23E37639' stroke-width='2.57143'/%3E%3C/svg%3E%0A");
}

.react-multi-select__indicators {
  height: 100%;
}
