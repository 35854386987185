@import "assets/styles/colors";

.order {
  background-color: $azure;
  padding: 40px 44px 40px 46px;
  min-width: 522px;

  .orderName {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: $blackCoral;
    margin-bottom: 10px;
  }

  .label {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: $blackCoral;
    margin-bottom: 10px;
  }

  .info {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $blackCoral;
    margin-bottom: 18px;
  }

  .change {
    display: flex;
    align-items: center;
  }

  .select {
    width: 100%;
    max-width: 289px;
    margin-right: 30px;
  }

  .currentStatus {
    padding-bottom: 20px;
    border-bottom: 1px solid $grayishBlue;
    margin-bottom: 24px;
  }

  .comment {
    padding-bottom: 20px;
    border-bottom: 1px solid $grayishBlue;
    margin-bottom: 24px;

    .action {
      display: flex;
      margin-top: 12px;
      width: 100%;
      justify-content: flex-end;
    }
  }

  .client {
    border-bottom: 1px solid $grayishBlue;
    margin-bottom: 24px;

    a {
      text-decoration: underline;
      color: inherit;
    }
  }

  .contact {
    padding-bottom: 20px;
    border-bottom: 1px solid $grayishBlue;
    margin-bottom: 24px;
  }

  .commentText {
    display: block;
    margin-top: 10px;
    color: $blackCoral;
    font-size: 14px;
  }
}
