@import "assets/styles/colors";

.select__wrap {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  min-width: 200px;
}

.select__label {
  position: absolute;
  top: 0;
  left: 17px;
  z-index: 1;
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: scale(1) translateY(17px);
  color: $grayishBlue;
  transform-origin: top left;

  &.hideLabel {
    display: none;
  }
}

.select__label_focused {
  transform: scale(0.8) translateX(0px) translateY(8px);
}

.error {
  font-size: 14px;
  line-height: 18px;
  color: #f21b1b;
  margin-top: 8px;
}
