@import "assets/styles/colors";
@import "assets/styles/mixins";

.radio {
  margin-top: 0;
}

.isActive {
  color: $brightOrange;
}

.containerItem {
  color: $darkGray;
  border-bottom: 1px solid $grayLight;
  // border-top: 1px solid $grayLight;

  &:nth-child(2) {
    border-top: 1px solid $grayLight;
  }

  &:nth-child(8) {
    border-top: 1px solid $grayLight;
  }

  .titleWrapper {
    width: 20%;
  }

  .header {
    font-size: 15px;
    line-height: 1;
    position: relative;
    padding: 25px 21px 25px 30px;
    cursor: pointer;

    .titleBlock {
      display: flex;
      flex-direction: column;
    }

    .creditTitle {
      font-size: 12px;
      line-height: 140%;
      font-weight: bold;
      color: #e37639;
    }

    .main {
      display: flex;
      align-items: center;

      .title {
        font-weight: bold;
        text-transform: uppercase;
      }

      .subtitle {
        width: 59%;
      }

      .creditInfo {
        width: 30%;
        color: $brightOrange;
      }

      .price {
        width: 10%;
        font-weight: bold;
      }

      span {
        display: flex;
        align-items: center;
        flex-direction: row;
      }

      svg {
        margin-left: 20px;
        flex: 0 0 25px;
        transition: transform 0.3s;
      }

      .input {
        background: transparent;
      }
    }
  }

  .body {
    padding: 30px 30px 30px 86px;
    display: none;

    & > p {
      margin-bottom: 20px;
    }
  }

  &.disabled {
    opacity: 0.8;
  }

  &.opened {
    & > .header {
      background-color: $azure;

      & svg {
        transition: transform 0.3s;
        transform: rotate(180deg);

        path {
          stroke: $brightOrange;
        }
      }
    }

    & > .body {
      display: flex;
      flex-flow: row wrap;
    }
  }
}

.fullRow {
  width: 66%;
  color: $darkGray;

  .fullRowTitle {
    font-weight: bold;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 8px;
    color: $darkGray;
    opacity: 0.6;
  }

  .fullRowText {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    margin-bottom: 24px;
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 23px;
  grid-row-gap: 30px;

  .tableTitle {
    font-weight: bold;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $darkGray;
    margin-bottom: 8px;
    opacity: 0.6;
  }

  .tableText {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: $darkGray;
  }
}
