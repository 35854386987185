@import "assets/styles/colors";

.manager {
  display: flex;
  flex-direction: column;
  width: 290px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: $darkGray;

  .name {
    font-weight: bold;
  }

  .dealer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}
