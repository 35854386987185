@import "assets/styles/colors";

.container {
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin-bottom: 20px;

  .message {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: $darkGray;
    display: flex;
    justify-content: center;
  }
}

.addCarButton {
  width: 329px;
  margin-top: 77px;
}
