@import "assets/styles/colors";

.background {
  background-color: $brightGray;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-right: 0;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.checkbox ~ span,
.icon {
  display: none;
}

.body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;

  .title {
    color: $darkGray;
  }

  .arrows {
    display: none;

    button {
      background: $white;
      border-color: transparent;

      &:first-child {
        margin-right: 3px;
      }
    }

    svg {
      width: 10px;
    }
  }
}

.item {
  background-color: #fff;
  height: 229px;
  padding: 25px 25px 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.disabled {
  opacity: 0.7;
  cursor: default;
}

.benefit {
  color: $darkGray;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.desc {
  flex: 1 0 auto;
  color: $darkGray;
  font-size: 16px;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: $grayLight;
  margin-top: 17px;
  margin-bottom: 17px;
}

.add {
  background-color: #fff;
  border: 1px solid $brightOrange;
  color: $brightOrange;
  transition: color 0.3s;

  &:hover {
    color: #fff;
  }
}

.disabledButton {
  border-color: $nero;
  color: $nero;
  cursor: default;
}

.slider {
  :global .slick-slide > div {
    margin: 0 10px;
  }

  :global .slick-list {
    margin: 0 0 0 -10px;
  }
}

.sliderToLeft {
  :global .slick-track {
    margin-left: 0 !important;
  }
}

@media (max-width: 1024px) {
  .header {
    .arrows {
      display: flex;
    }
  }
}

@media (max-width: 500px) {
  .background {
    padding-top: 46px;
    padding-bottom: 50px;
  }

  .item {
    height: auto;
    margin-bottom: 10px;
    padding-top: 21px;
    padding-bottom: 21px;
  }

  .header {
    margin-bottom: 24px;

    .title {
      font-size: 28px;
    }
  }

  .slider {
    :global .slick-slide > div {
      margin: 0;
    }

    :global .slick-list {
      margin: 0;
    }
  }

  .header {
    .arrows {
      display: none;
    }
  }

  .checkbox {
    margin-top: 2px;
  }

  .checkbox ~ span {
    display: inline-flex;

    &:before {
      border-color: $brightOrange !important;
    }
  }

  .separator {
    display: none;
    width: calc(100% + 50px);
    margin: 17px -25px;
  }

  .benefit {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    line-height: 23px;
  }

  .body {
    max-height: 0;
    overflow: hidden;

    & > button {
      height: 35px;
    }
  }

  .icon {
    display: block;
  }

  .item.open {
    padding-bottom: 27px;

    .separator {
      display: block;
    }

    .icon {
      transform: rotate(180deg);

      svg path {
        stroke: $brightOrange;
      }
    }

    .body {
      max-height: none;
      height: 93px;
    }
  }
}

.iconCheck {
  display: inline-block;
  border-radius: 50%;
  background-color: $brightOrange;
  width: 21px;
  height: 21px;
  margin-right: 10px;

  svg {
    display: inline-block;
    width: 100%;
  }

  @media (max-width: 575px) {
    svg {
      width: 21px;
      height: 10px;
    }
  }
}

.isDealer {
  color: $brightOrange;
}

.text {
  color: $brightOrange;
  font-weight: 700;
}

.sliderContainer {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}
