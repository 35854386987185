@import "assets/styles/colors";

.carsPlaced {
  thead > tr > th {
    &:nth-of-type(1) {
      width: 12%;
    }

    &:nth-of-type(2) {
      width: 12%;
    }

    &:nth-of-type(3) {
      width: 20%;
    }

    &:nth-of-type(4) {
      width: 11%;
    }

    &:nth-of-type(5) {
      width: 10%;
    }

    &:nth-of-type(6) {
      width: 10%;
    }

    &:nth-of-type(7) {
      width: 13%;
    }

    &:nth-of-type(8) {
      width: 12%;
    }
  }
}

.table {
  border-collapse: collapse;
  width: 100%;

  .sort {
    position: relative;
    cursor: pointer;

    &::after {
      cursor: pointer;
      content: "";
      position: absolute;
      right: -26px;
      bottom: -1px;
      border: 6px solid transparent;
      border-top: 5px solid $blackCoral;
    }

    &.activeASC {
      &::after {
        border-top: 5px solid $brightOrange;
        transform: rotate(180deg);
        bottom: 4px;
      }
    }
    &.activeDESC {
      &::after {
        border: 6px solid transparent;
        border-top: 5px solid $brightOrange;
      }
    }
  }

  thead > tr > th {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $sandStone;
    padding-bottom: 32px;
    padding-right: 20px;
  }

  tbody {
    .info {
      border-top: 1px solid $grayishBlue;

      td {
        padding-top: 40px;
        padding-bottom: 10px;
      }
    }

    .actions {
      border-bottom: 1px solid $grayishBlue;

      td {
        padding-bottom: 32px;
      }
    }
  }

  tbody > tr > td {
    display: table-cell;
    vertical-align: top;
    padding-right: 10px;
  }

  tbody > tr > td > span {
    display: block;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    margin-top: 4px;
    color: $darkGray;
  }

  .button {
    text-align: right;
    padding-right: 0;

    button {
      margin-left: 20px;
    }
  }

  .tableAction {
    color: $brightOrange;
  }

  .vin {
    margin-top: 10px;
  }

  .phone {
    margin-top: 10px;
  }

  .bold {
    font-weight: bold;
  }
}

.tableFooter {
  margin-top: auto;
}

.preloader {
  display: flex;
  justify-content: center;
  padding-top: 70px;
  width: 100%;
}

.manufactureCarLabel {
  border: 1px solid #e37639;
  padding: 3px 13px;
  text-align: center;
  color: var(--e-37639, #e37639);
  font-size: 13px;
  line-height: 21px;
  margin-top: 10px;
}
