@import "assets/styles/colors";

.modalBody {
  button {
    width: 100%;
    margin-top: 20px;
  }
}

.modal {
  display: flex;
  justify-content: center;
}

.cancelModal {
  .modal {
    .description {
      font-size: 17px;
    }
  }

  .modalBody {
    .radioGroup {
      display: flex;
      flex-direction: column;

      > label {
        height: fit-content;
        margin-bottom: 10px;

        span {
          font-size: 15px;
        }

        span::before {
          border: 1px solid #66727d !important;
        }
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    .radioMargin {
      > :last-child {
        margin-bottom: 20px;
      }
    }
  }
}
