$white: #ffffff;
$gray: #262626;
$darkGray: #4c5865;
$aubergine: #221f20;
$sandStone: #848e98;
$azure: #f7f8f8;
$nero: #d9dcdc;
$brightOrange: #e37639;
$orange: #e6873b;
$grayishBlue: #a6adb5;
$blackPearl: #66727d;
$grayLight: #eaefef;
$black: #000000;
$platinum: #e7ecec;
$darkSlateGray: #262626;
$lightSlateGray: #353535;
$blackCoral: #54616c;
$blackBrown: #1f1f1f;
$blackGray: #181818;
$green: #74b974;
$poorGray: #e0e0e0;
$philippineGray: #919191;
$brightGray: #eaeaea;
$cultured: #f4f4f4;
$manatee: #969ca3;
$lightSilver: #d9d9d9;
$redDamask: #e37639;
