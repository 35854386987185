@import "assets/styles/colors";

.background {
  background-color: $azure;
}

.container {
  padding-top: 80px;
  padding-bottom: 70px;
}

.title {
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 32px;
}

.list {
  margin-bottom: 40px;
}

.itemTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: $darkGray;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.itemName {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    cursor: pointer;
    margin-left: 12px;

    &:hover {
      path {
        fill: $brightOrange;
      }
    }
  }
}

.itemTitle {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: $blackCoral;
}

.itemPrice {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  color: $darkGray;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;

  .select {
    margin-right: 20px;
    width: 60%;
  }

  .input {
    margin-right: 20px;
    width: 25%;
  }

  .action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 42%;
  }
}
