@import "assets/styles/colors";

.container {
  padding-top: 80px;
  padding-bottom: 70px;
}

.titleWithButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;

  .title {
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $darkGray;
  }

  .switch {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      line-height: 14px;
      color: $darkGray;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
  }
}

.action {
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
