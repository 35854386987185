@import "assets/styles/colors";

div.container {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  width: 100%;

  input {
    outline: none;
    height: 48px;
    width: 100%;
    padding: 12px 17px 0 17px;
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid $nero;
    font-size: 15px;
    border-radius: 0;

    &::placeholder {
      color: $sandStone;
      opacity: 1;
      transition: all 0.3s;
    }

    &:focus::placeholder {
      opacity: 0.6;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      background-color: #fafafa !important;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  div.error {
    position: absolute;
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    color: #f21b1b;
    margin-top: 3px;
  }
}

.label {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 17px;
  z-index: 1;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: scale(1) translateY(17px);
  color: $grayishBlue;
  transform-origin: top left;

  &.focused {
    transform: scale(0.8) translateX(0px) translateY(8px);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
}
