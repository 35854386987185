@import "assets/styles/colors";

.btn {
  cursor: pointer;
  height: 34px;
  display: inline-block;
  border-radius: 17px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 9px 18px;
  border: 1px solid $brightOrange;
  outline: none;
  text-align: center;
  white-space: nowrap;
  line-height: 16px;

  color: $brightOrange;
  background-color: transparent;
  @media (min-width: 768px) {
    &:hover {
      color: $white;
      background-color: $brightOrange;
    }
  }

  &.isGray {
    color: #a5a5a5;
    border-color: $nero;
  }

  &.disabled {
    color: #a5a5a5;
    border-color: $nero;

    @media (min-width: 768px) {
      &:hover {
        color: transparent;
        background-color: transparent;
        color: #a5a5a5;
        cursor: default;
      }
    }
  }
}

@media (max-width: 767px) {
  .btn {
    width: max-content;
    height: 45px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 35px;
  }
}
