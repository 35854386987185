@import "assets/styles/colors";

.checkbox {
  position: relative;
  height: 24px;
  margin-top: 8px;
}

.checkbox_disabled {
  & span:before {
    background-color: #f5f5f5 !important;
  }

  &.checkbox_bordered {
    & span:before {
      background-color: transparent !important;
      opacity: 0.3;
    }
  }
}

.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 24px;
}

.checkbox span {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  color: #66727d;
}

.checkbox span:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 1px;
  margin-right: 13px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.checkboxSpec {
  span:before {
    background-color: $blackGray;
    border: 1px solid rgba(166, 173, 181, 0.5);
  }
}

.checkbox input:not(:disabled):not(:checked) + span:hover:before {
  //border-color: #ffaa14;
  cursor: pointer;
}

.checkbox > input:disabled:checked + span::before {
  border-color: rgba(166, 173, 181, 0.2);
  background-color: #f5f5f5;
  background-size: 15px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.5 1L5.5 9L1 5' stroke='%23E37639' stroke-width='2.57143'/%3E%3C/svg%3E%0A");
}

.checkbox > input:not(:disabled):checked + span::before {
  border-color: rgba(166, 173, 181, 0.2);
  background-size: 15px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.5 1L5.5 9L1 5' stroke='%23E37639' stroke-width='2.57143'/%3E%3C/svg%3E%0A");
}

.checkbox_bordered {
  & > input:not(:disabled):checked + span::before {
    background-color: $brightOrange;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.5 1L5.5 9L1 5' stroke='%23FFFFFF' stroke-width='2.57143'/%3E%3C/svg%3E%0A");
  }

  & span:before {
    background-color: transparent;
    border-radius: 50%;
  }
}

@media (max-width: 500px) {
  .checkbox span:before {
    margin-right: 11px;
    width: 20px;
    height: 20px;
  }
}
