@import "assets/styles/colors";

.background {
  background-color: $azure;
  height: 100%;
  min-height: 100vh;
}

.container {
  padding: 50px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .topWrap {
    display: flex;
    justify-content: space-between;
  }

  .analytics {
    display: flex;
    justify-content: space-between;
    width: 150px;

    &:hover rect {
      fill: $white;
    }
  }
}

.title {
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 20px;
}
