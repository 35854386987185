@import "assets/styles/colors";

.container {
  margin-top: 40px;
  margin-bottom: 80px;
}

.image {
  margin-right: 150px;

  img {
    max-width: 620px;
  }
}

.btnBack {
  margin-bottom: 24px;
  padding: 0;
  height: auto;
  color: $blackCoral;

  span {
    width: 18px;
  }
}

.header {
  display: flex;
}
.content {
  margin-top: 80px;
}

.info {
  display: flex;
  flex-direction: column;
  color: $blackCoral;
  margin-bottom: 18px;

  .label {
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .modification {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .engine {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }
}

.price {
  display: flex;
  flex-direction: column;

  .label {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $sandStone;
  }

  .commonPrice {
    font-weight: normal;
    font-size: 22px;
    line-height: 33px;
    text-decoration-line: line-through;
    color: $blackCoral;
    margin-bottom: 2px;
  }

  .currentPrice {
    font-weight: normal;
    font-size: 36px;
    line-height: 33px;
    color: $redDamask;
    margin-top: 6px;
  }
}

.switch {
  display: flex;
  align-items: center;
  margin-top: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
    color: $darkGray;
  }
}

.text {
  display: flex;
  flex-direction: column;
}

.manufactureBlock {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;

  p {
    margin-left: 10px;
  }
}
