@import "assets/styles/colors";

.background {
  background-color: $azure;
}

.container {
  padding-top: 80px;
  padding-bottom: 70px;
}

.titleWithButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;

  .title {
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $darkGray;
  }

  .switch {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      line-height: 14px;
      color: $darkGray;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
  }
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 135.5%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 20px;
}

.table {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1.1fr;
  grid-row-gap: 14px;
  padding-top: 10px;
  margin-bottom: 44px;

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .tableTitle {
    width: 35%;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: $sandStone;
  }

  .tableItem {
    width: 50%;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: $darkGray;
  }
}

.formCar {
  padding-top: 26px;
  margin-bottom: 64px;

  .rowForm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &:first-child {
      margin-bottom: 20px;
    }

    div {
      width: 25%;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    .action {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 25%;
    }
  }
}
