.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 31px;

  > div {
    width: calc((100% - 26px) / 3);
    margin-right: 13px;

    &:last-child {
      margin-right: 0;
    }
  }

  .wrapperCalendar {
    position: relative;
    cursor: pointer;
    align-items: center;
    background-color: #fff;
    border-color: #d9dcdc !important;
    border-style: solid;
    border-width: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    transition: all 100ms;
    box-sizing: border-box;
    border-bottom-width: 1px;

    .icon {
      position: absolute;
      display: flex;
      align-items: center;
      left: 16px;
      top: 13px;

      span {
        padding-top: 3px;
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #66727d;
      }
    }

    .resetBtn {
      position: absolute;
      right: 16px;
      top: 18px;
      z-index: 1;
    }

    .input {
      span {
        padding-left: 22px;
        color: #bdc2c7;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.select {
  :global .css-tp6v0y-singleValue {
    font-weight: normal;
    line-height: 33px;
  }
}

.flex {
  justify-content: flex-start;
}
