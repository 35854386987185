@import "assets/styles/colors";

.formBlock {
  margin-bottom: 80px;

  &:nth-child(3) {
    margin-bottom: 0;
  }
}

.formLine {
  position: relative;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  &.full {
    display: block;

    & .input {
      width: calc(50% - 10px);
    }
  }

  & .input {
    width: 50%;
  }

  &_margin {
    margin-bottom: 60px !important;
  }

  &_fields {
    > div {
      width: calc(100% / 3);
    }
  }
}

.date {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 14px 17px 0 17px;
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #d9dcdc;
  cursor: default;

  span {
    padding-left: 12px;
    color: #bdc2c7;
    font-size: 14px;
    font-weight: 400;
  }
}

.emtyDate {
  display: flex;
  align-items: center;
}

.calendar {
  top: inherit;
  bottom: 0;
  left: 0;
}

.selectedDate {
  display: flex;
  align-items: center;
  height: 100%;
  span {
    font-size: 15px;
    color: #000000;
  }
}

.title {
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkGray;
  margin-bottom: 32px;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.requiredText {
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #9d9d9d;
  margin-bottom: 32px;
}
