@import "assets/styles/colors";
@import "assets/styles/mixins";

.container {
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content !important;
  padding: 32px;
  margin-right: -13px !important;
  background-color: $white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 2;

  @include xs {
    max-width: 360px;
    padding: 20px;
    margin-right: 0 !important;
    //width: 100% !important;
  }

  .closeBtn {
    display: flex;
    justify-content: right;
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 0;

    @include xs {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      button {
        height: 35px;
        width: 100%;
      }
    }
  }

  .inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include xs {
      margin: 0 0 20px;
      width: 100%;
    }

    div {
      display: flex;
      flex-direction: column;

      @include xs {
        width: calc(50% - 25px);
      }

      span {
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: #a6adb5;
      }

      input {
        height: 26px;
        max-width: 105px;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        color: #66727d;
        border: none;
        border-bottom: 1px solid #d9dcdc;

        @include xs {
          width: 100%;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }

    .line {
      width: 10px;
      height: 1px;
      margin: 0 12px;
      border-bottom: 1px solid #4c5865;
    }
  }
}

.position {
  top: 102px;
  right: 9px;

  @include xs {
    top: 80px;
    right: 0;
    left: -23px;
  }
}
